import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import agent from '../../agent';
import Header from '../../components/header-section/header-section.component';
import Footer from '../../components/footer-section/footer-section.component';
import './footer-page-styles.scss'
import { Container } from 'react-bootstrap';
import PageNotFound from '../../components/page-not-found/page-not-found.component';
import InternalServerError from '../../components/internal-server-error/internal-server-error.component';

function FooterPage() {
  const { slug } = useParams();
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [data, setData] = useState();

  useEffect(() => {
    const fetchPages = () => {
      agent.infraData.getPages(slug).then(res => {
        if (res.status === 404 || res.status === 500) {
          throw new Error(Number(res.status))
        } else {
          setData(res.data)
        }
      }).catch((e) => {
        if (Number(e.message) === 404) {
          setNotFound(true)
        }
        if (Number(e.message) === 500) {
          setServerError(true)
        }

      })
    }
    fetchPages()
  }, [slug, notFound, serverError]);
  return (
    <>
      {notFound ? <PageNotFound /> : serverError ? <InternalServerError /> :
        <>
          <Header />
          <Container>
            {
              (data !== undefined && data !== null) && (
                <>
                  <div className='align-items-page'>
                    <div className="intro-block">
                      <h3 className='title'>{data.page.title}</h3>
                      <p className='description' dangerouslySetInnerHTML={{ __html: data.page.description }}></p>

                    </div>
                  </div>
                </>
              )
            }

          </Container>
          <Footer />
        </>

      }
    </>
  )
}

export default FooterPage