import { ProjectActionTypes } from './project.types';
import { handleFilter } from './project.utils';

const INITIAL_STATE = {
  projectList: [],
  projectFilterList: null,
  paginatedProjectList: null,
  sortType: "",
  searchString: "",
}

const projectListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProjectActionTypes.SET_PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload
      }
    case ProjectActionTypes.SET_PAGINATED_PROJECT_LIST:
      return {
        ...state,
        paginatedProjectList: action.payload
      }
    case ProjectActionTypes.SET_FILTERED_LIST:
      return {
        ...state,
        sortType: action.payload.sortType,
        searchString: action.payload.searchTerm,
      }
    case ProjectActionTypes.UPDATE_PROJECT_ACTIVE:
      return {
        ...state,
        projectList: [...handleFilter(action.payload, state.projectList)]
      }
    case ProjectActionTypes.SET_PROJECT_FILTER_LIST:
      return {
        ...state,
        projectFilterList: action.payload,
      }

    default:
      return state
  }
}

export default projectListReducer