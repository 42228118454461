import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MapContainer, ScaleControl, ZoomControl, useMap } from 'react-leaflet';
import BaseMap from './base-map.component';
import GeoLayer from './geo-layer.component';
import ProjectSidemenu from '../project-sidemenu/project-sidemenu-component';
import { selectCenter, selectZoom } from '../../redux/about-app/about-app.selectors';
import './map-section.styles.scss'

function ChangeMapView({ coords, zoom }) {
  const map = useMap();
  map.flyTo(coords, zoom);
  return null;
}

const MapSection = ({ center, zoom }) => {
  return (
    <div>
      <ProjectSidemenu />
      <div className='map__section' id="map">
        <MapContainer className="leaflet_map" center={center} zoom={zoom} scrollWheelZoom={true} zoomControl={false}>
          <ZoomControl position="bottomright" />
          <ChangeMapView coords={center} zoom={zoom} />
          <ScaleControl position="bottomleft" />
          <BaseMap />
          <GeoLayer />
        </MapContainer>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  center: selectCenter,
  zoom:selectZoom
})

export default connect(mapStateToProps)(MapSection)