import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAboutApp } from "../../redux/about-app/about-app.selectors";
import { Link } from "react-router-dom";
import './footer-section.styles.scss'

const FooterSection = ({ aboutApp }) => {
  return (
    <>
      {
        aboutApp !== null && (
          <footer className="footer">
            <div className="container">
              <div className="footer-top">
                <div className="row">
                  <div className="col-lg-10 m-auto">
                    <div className="footer_block mb-4">
                      <h5 className="footer_block-title">About Us</h5>
                      <div className="footer_block-item">
                        <p>{aboutApp.short_description}</p>
                      </div>
                    </div>
                    <div className="footer_block">
                      <h5 className="footer_block-title">Contact Us</h5>
                      <div className="footer_block-item">{aboutApp.contact_us}</div>
                    </div>
                    <div className="footer_block-link">
                      <a href={`${aboutApp.facebook_url}`} target="_blank" ><i className="fa-brands fa-facebook-f"></i></a>
                      <a href={`${aboutApp.twitter_url}`} target="_blank"><i className="fa-brands fa-twitter"></i></a>
                      <a href={`${aboutApp.linked_url}`} target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                      <a href={`${aboutApp.instagram_url}`} target="_blank"><i className="fa-brands fa-instagram"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-btm-sec">
              <div className="container">
                <div className="footer-bottom">© 2022 Policy Entrepreneurs, Inc. &nbsp;&nbsp;|&nbsp;&nbsp; All Right Reserved &nbsp;&nbsp;|&nbsp;&nbsp; Deveoped By <a href={`${aboutApp.facebook_url}`} target="_blank" >Ekbana</a> &nbsp;&nbsp;|&nbsp;&nbsp; Powered By <a href="#">Pointnemo</a> &nbsp;&nbsp;|&nbsp;&nbsp; <Link to="/pages/privacy-policy">Privacy/Cookie Policy</Link></div>
              </div>
            </div>
          </footer>
        )
      }
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  aboutApp: selectAboutApp
})

export default connect(mapStateToProps)(FooterSection)