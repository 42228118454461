import React, { useState } from 'react'
import * as yup from 'yup';
import { Button, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import agent from '../../agent';
import './contact-us.styles.scss';

const INITIAL_STATE = {
  name: '',
  email: '',
  contact_person: '',
  project_name: '',
  message: '',
}

const contactSchema = yup.object().shape({
  name: yup.string().required("The name field is required").max(100, "Name should not be more the 100 characters"),
  email: yup.string().email().required("The email field is required"),
  contact_person: yup.string().max(100, "Contact Person name should not be more the 100 characters"),
  project_name: yup.string().max(100, "Project name should not be more the 100 characters"),
  message: yup.string().required("The message field is required").max(500, "The message should not be more then 500 characters")
})

const ContactUs = () => {
  const [disabled, setDisabled] = useState(false)
  const [details, setDetails] = useState(INITIAL_STATE)
  const [isSuccess, setIsSuccess] = useState(null)
  const [successMsg, setSuccessMsg] = useState()
  const [errMsg, setErrMsg] = useState(null)

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true)
    contactSchema.validate(details, { abortEarly: false }).then(() => {
      const body = `name=${details.name}&email=${details.email}&project_name=${details.project_name}&contact_person=${details.contact_person}&message=${details.message}`
      agent.infraData.addMessage(body).then((res) => {
        setDisabled(false)
        if (res.data) {
          setIsSuccess(true)
          setDetails(INITIAL_STATE)
          setSuccessMsg(res.data.message)
          setErrMsg()
          setTimeout(() => {
            setIsSuccess(false)

          }, 5000)
        } else {
          setDisabled(false)
          setIsSuccess(false)
          setSuccessMsg(res.errors[0].detail)
        }

      }).catch((err) => {
        setDisabled(false)
        setIsSuccess(false)
      })
    }).catch((err) => {
      setDisabled(false)
      const validationErrors = {}
      err.inner.forEach(err => {
        if (err.path) {
          validationErrors[err.path] = err.message;
        }
      })
      setErrMsg(validationErrors)
    })
  }
  return (
    <div className="contact-form bg-white">
      <Container>
        <Form onSubmit={handleSubmit}>
          <h1 className="contact-form_title">Get In Touch</h1>

          <div className="contact-form_feilds">
            {isSuccess && (
              <Alert variant="success" onClose={() => setIsSuccess(false)} dismissible>{successMsg}</Alert>
            )}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control type="text"
                    placeholder="Full Name"
                    name='name'
                    value={details.name}
                    required
                    onChange={handleChange}
                    isInvalid={errMsg?.name} />
                  <Form.Control.Feedback type="invalid">
                    {errMsg?.name}
                  </Form.Control.Feedback>

                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control type="text"
                    placeholder="Email"
                    name='email'
                    required
                    value={details.email}
                    onChange={handleChange}
                    isInvalid={errMsg?.email} />
                  <Form.Control.Feedback type="invalid">
                    {errMsg?.email}
                  </Form.Control.Feedback>

                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="projectname">
                  <Form.Control type="text"
                    placeholder="Project Name (if available)"
                    name='project_name'
                    value={details.project_name}
                    onChange={handleChange}
                    isInvalid={errMsg?.project_name} />
                  <Form.Control.Feedback type="invalid">
                    {errMsg?.project_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="person">
                  <Form.Control type="texts"
                    placeholder="Contact Person (if available)"
                    name='contact_person'
                    value={details.contact_person}
                    onChange={handleChange}
                    isInvalid={errMsg?.contact_person} />
                  <Form.Control.Feedback type="invalid">
                    {errMsg?.contact_person}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="person">
                  <Form.Control as="textarea"
                    name="message"
                    rows={3}
                    placeholder='Your Message'
                    value={details.message}
                    onChange={handleChange}
                    required
                    isInvalid={errMsg?.message} />
                  <Form.Control.Feedback type="invalid">
                    {errMsg?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Button className='btn-lg' type="submit" disabled={disabled}>
              Submit Now
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default ContactUs