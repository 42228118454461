import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NoImg from '../../assets/images/No_image.png';
import { selectPaginatedProjectList } from '../../redux/project/project.selector';
import { setPaginatedProject } from '../../redux/project/project.action';
import { Container, Image, Pagination, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import agent from '../../agent';
import { createStructuredSelector } from 'reselect';
import FileErrorImage from '../../assets/images/icons/file-error.svg';

function ProjectDatabaseTable({ projectList, setPaginatedProject }) {
  const gotoPage = async (page) => {
    if (page > 0 && page !== projectList.page && page <= projectList.total_page) {
      agent.infraData.getProjectList(true, page).then((res) => {
        setPaginatedProject(res)
      }).catch((err) => {
      })
    }
  }
  useEffect(() => {
    agent.infraData.getProjectList(true, 1).then((res) => {
      setPaginatedProject(res)
    }).catch((err) => {
    })
  }, []);

  return (
    <div className="project-database_table">
      <Container>
        {(projectList !== null) && (
          <>
            {(projectList.project.length > 0) ? (
              <>
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Project Name</th>
                      <th>Address</th>
                      <th>Start Year</th>
                      <th>End Year</th>
                      <th>Cost</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectList.project.map((project) => {
                        return (

                          <tr key={`project-list-${project.id}`}>
                            <td>
                              {
                                project.display_image === "" || null ? (
                                  <div className="project-image">
                                    <Image src={NoImg} />
                                  </div>
                                ) : (
                                  <>
                                    <div className="project-image">
                                      <Image src={project.display_image} />
                                    </div>
                                  </>
                                )
                              }

                            </td>
                            <td>{project.name}</td>
                            <td>
                              <div className="author">
                                <div className="author-info">
                                  <h5>{project?.address ?? "--"}</h5>
                                </div>
                              </div>
                            </td>
                            <td>{project?.start_date ?? "--"}</td>
                            <td>{project?.end_date ?? "--"}</td>
                            <td>{project?.cost_in_words ?? "--"}</td>
                            <td>
                              <Link className='btn btn-info text-nowrap' target="_blank" to={`/project/detail/${project.id}`}>View More</Link>
                            </td>
                          </tr>

                        )
                      })
                    }

                  </tbody>
                </Table>
                <Pagination className='justify-content-end'>
                  <Pagination.Prev active={projectList.is_first} onClick={() => gotoPage(projectList.page - 1)}>Previous</Pagination.Prev>
                  {
                    Array.from(Array(projectList.total_page), (e, i) => {
                      return (<Pagination.Item key={i} active={i + 1 === projectList.page} onClick={() => gotoPage(i + 1)}>{i + 1}</Pagination.Item>)
                    })
                  }
                  <Pagination.Next active={projectList.is_last} onClick={() => gotoPage(projectList.page + 1)} >Next</Pagination.Next>
                </Pagination>
              </>
            ) : (
              <div className="data-not-found">
                <Image src={FileErrorImage} className="img-fluid" />
                <h3>Sorry, No Data found.</h3>
                <p>Please refine your search criteria or enter a different keyword.</p>
              </div>

            )}
          </>
        )}
      </Container>
    </div >
  )
}

const mapStateToProps = createStructuredSelector({
  projectList: selectPaginatedProjectList
})

const mapDispatchToProps = dispatch => ({
  setPaginatedProject: projectList => dispatch(setPaginatedProject(projectList))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDatabaseTable)