import React from 'react';
import { Container } from 'react-bootstrap';
import './project-page-styles.scss';
import HeaderSection from '../../components/header-section/header-section.component';
import FooterSection from '../../components/footer-section/footer-section.component';
import ProjectDatabaseTable from '../../components/project-database/project-database-table';
import ProjectDatabaseFilter from '../../components/project-database/project-database-filter.component';
import '../../components/project-database/project-table.styles.scss';
const ProjectsPage = () => {
  return (<>
    <div className="project-wrapper">
      <HeaderSection />
      <div className='project-content'>
        <div className='project-database'>
          <div className="project-database_header">
            <Container>
              <h1 className="title">Project Database</h1>
              <p>This database displays all the project profiles featured on the Map in alphabetical order. Here you can select multiple filters to cross check the profiles according to a number of criteria, including type of project, cost, location, companies and financiers involved, and impacts.</p>
            </Container>
          </div>

          <ProjectDatabaseFilter />
          <ProjectDatabaseTable />
        </div>
        <FooterSection />
      </div>
    </div>
  </>)
}

export default ProjectsPage