import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { GeoJSON, Marker, Tooltip } from "react-leaflet";
import { selectFilteredList, selectActiveProject } from "../../redux/project/project.selector";
import { updateActiveProject } from "../../redux/project/project.action";
import { setCenter, setZoom } from "../../redux/about-app/about-app.actions";
import { pointerIcon } from "../../utils/util";
import L from 'leaflet';

const GeoLayer = ({ projectList, updateActive, setCenter, setZoom, activeProject }) => {

  const onEachFeature = (feature, layer) => {
    let tileStyleGroup = {
      weight: (feature.type === "LineString") ? 3 : 1,
      color: "#635c5c",
      opacity: 1,
      fillColor: "#322196",
      fill: (feature.type !== "LineString"),
      radius: 7,
      fillOpacity: 0.4
    }
    layer.setStyle(tileStyleGroup)
  }

  const pointToLayer = (feature, latlng) => {
    if (latlng) {
      return L.circleMarker(latlng, {
        radius: 10
      })
    }
  }
  return (
    <>
      {(activeProject !== undefined) && (
        <GeoJSON key={activeProject.id} data={activeProject.geojson} onEachFeature={onEachFeature} pointToLayer={pointToLayer} />
      )}
      {
        projectList.map(project => {
          return (<div key={`pointer-${project.id}`}>
            {project.location.map((location, index) => (
              <Marker key={`pointer-${project.id}-${index}`} position={location} icon={pointerIcon(project.type_icon)}
                eventHandlers={{
                  click: () => {
                    setZoom(15)
                    updateActive(project.id)
                    setCenter(location)
                  }
                }}>
                <Tooltip direction="top" offset={[0, -15]} opacity={.8} >
                  {project.name}
                </Tooltip>
              </Marker>
            ))}
          </div>
          )
        }
        )
      }
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  projectList: selectFilteredList,
  activeProject: selectActiveProject
})

const mapDispatchToProps = dispatch => ({
  updateActive: projectList => dispatch(updateActiveProject(projectList)),
  setCenter: aboutApp => dispatch(setCenter(aboutApp)),
  setZoom: aboutApp => dispatch(setZoom(aboutApp))
})
export default connect(mapStateToProps, mapDispatchToProps)(GeoLayer)