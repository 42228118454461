import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MapContainer, Marker, GeoJSON, useMap } from 'react-leaflet';
import BaseMap from '../../map-section/base-map.component';
import { selectCenter } from '../../../redux/about-app/about-app.selectors';
import { pointerIcon } from '../../../utils/util';
import L from 'leaflet';

function ChangeMapView({ coords, zoom }) {
  const map = useMap();
  map.setView(coords, zoom, { animate: true });
  return null;
}

function ProjectDetailContact({ project, center }) {

  const onEachFeature = (feature, layer) => {
    let tileStyleGroup = {
      weight: (feature.type === "LineString") ? 3 : 1,
      color: "#635c5c",
      opacity: 1,
      fillColor: "#322196",
      fill: (feature.type !== "LineString"),
      radius: 7,
      fillOpacity: 0.4
    }
    layer.setStyle(tileStyleGroup)
  }

  const pointToLayer = (feature, latlng) => {
    if (latlng) {
      return L.circleMarker(latlng, {
        radius: 10
      })
    }
  }

  return (
    <div className='project-contact'>
      {console.log(center, project.centroid)}
      {(project?.centroid) && (
        <div className="project-contact_map" id="project-map">
          <MapContainer className='leaflet_map' center={center} zoom="5" scrollWheelZoom={false} doubleClickZoom={false} zoomControl={false}>
            <BaseMap />
            <ChangeMapView coords={center} zoom="5" />
            {(project?.geojson) && (<GeoJSON data={project.geojson} onEachFeature={onEachFeature} pointToLayer={pointToLayer} />)}
            {project.location.map((location, index) => (
              <Marker key={`projct-detail-${project.id}-${index}`} position={location} icon={pointerIcon(project.type_icon)} />
            ))}
          </MapContainer>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  center: selectCenter
})

export default connect(mapStateToProps)(ProjectDetailContact)