class Helper {
  static slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  static titleFormatter = (params/*, hash*/) => {
    let str = params.replace(/_/g, " ");
    str = str.replace(/-/g, " ");
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
  }
}


export default Helper