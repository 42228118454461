import { createSelector } from "reselect";
import { filteredData, getActiveProject } from './project.utils';

export const projectListSelector = state => state.projectList

export const selectProjectList = createSelector(
  [projectListSelector],
  projectList => projectList.projectList
)

export const selectPaginatedProjectList = createSelector(
  [projectListSelector],
  projectList => projectList.paginatedProjectList
)

export const selectFilteredList = createSelector(
  [projectListSelector],
  projectList => filteredData(projectList.projectList, projectList.sortType, projectList.searchString)
)

export const selectActiveProject = createSelector(
  [projectListSelector],
  activeProject => getActiveProject(activeProject.projectList)
)

export const selectProjectFilterList = createSelector(
  [projectListSelector],
  projectList => projectList.projectFilterList

)
