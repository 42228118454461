import { createSelector } from "reselect";

export const selectAboutAppSelector = state => state.aboutApp;

export const selectAboutApp = createSelector(
  [selectAboutAppSelector],
  aboutApp => aboutApp.aboutApp
)

export const selectMajorProjects = createSelector(
  [selectAboutAppSelector],
  aboutApp => aboutApp.majorProjects
)

export const selectCenter = createSelector(
  [selectAboutAppSelector],
  aboutApp => aboutApp.center
)

export const selectZoom = createSelector(
  [selectAboutAppSelector],
  aboutApp => aboutApp.zoom
)

export const selectAttribuation = createSelector(
  [selectAboutAppSelector],
  aboutApp => aboutApp.attr
)