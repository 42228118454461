import React from 'react'
import { Link } from 'react-router-dom';
import './page-not-found.style.scss'

function PageNotFound() {
    return (
        <>
            <div className="page">
                <div className="page-not-found">
                    <h1>4<span>0</span>4</h1>
                    <p>We are Sorry, But the page you are requested was<br />not found!</p>

                    <Link to={"/"} className="btn btn-primary">Back to homepage <i className="fa-solid fa-arrow-right-long"></i></Link>
                </div>

            </div>
        </>
    )
}

export default PageNotFound