import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import NoImg from '../../assets/images/No_image.png';
import { createStructuredSelector } from "reselect";
import { selectFilteredList, selectActiveProject, projectListSelector } from '../../redux/project/project.selector';
import { setFilteredList, updateActiveProject } from '../../redux/project/project.action';
import { Button, Dropdown, FormControl, Image, InputGroup, Offcanvas } from 'react-bootstrap';
import { setCenter, setZoom } from '../../redux/about-app/about-app.actions';
import MapToggle from '../map-toggle-section/map-toggle.component';
import ProjectItem from './project-item/project-item.component';
import './project-sidemenu.styles.scss';

function ProjectSidemenu({ filteredList, projectList, filterList, updateActive, activeProject, setCenter, setZoom }) {
  const [show, setShow] = useState(false);
  const [projectItemShow, setProjectItemShow] = useState(false);

  const handleShow = () => setShow(!show);
  const handleClose = () => {
    setProjectItemShow(false);
  }
  const handleProjectItemShow = (project) => {
    updateActive(project.id)
    setCenter(project.centroid)
    setZoom(15)
    setProjectItemShow(true);
  }
  const handleSearch = (e) => {
    let { value } = e.target
    filteredList({ sortType: projectList.sortType, searchTerm: value })
  }

  const handleSort = (e) => {
    const sortData = e.target.innerText.toLowerCase()
    filteredList({ sortType: sortData, searchTerm: projectList.searchString, })
  }
  useEffect(() => {
    setProjectItemShow(true);
  }, [activeProject])


  return (
    <>
      <Button className={show ? 'btn-opened launch-btn' : 'launch-btn'} onClick={handleShow}>
        <i className={`fa-solid fa-caret-${show ? 'left' : 'right'}`}></i>
      </Button>
      <Offcanvas className="project-offcanvas" show={show} scroll={true} backdrop={false}>
        <Offcanvas.Header>
          <div className="offcanvas-header_detail">
            <Offcanvas.Title>
              <h5>Project</h5>
              {filterList !== undefined && (
                <p>{filterList.length} results found</p>

              )}
            </Offcanvas.Title>
            <div className='sort'>
              <Dropdown className='select_block'>
                <Dropdown.Toggle id="dropdown-basic">
                  {projectList.sortType === '' ? "Sort By" : projectList.sortType}
                  <i className="fa-solid fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSort}>Date</Dropdown.Item>
                  <Dropdown.Item onClick={handleSort}>Name</Dropdown.Item>
                  <Dropdown.Item onClick={handleSort}>Budget</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <InputGroup className="project-list_search">
            <InputGroup.Text id="basic-addon1">
              <i className="fa-solid fa-magnifying-glass"></i>
            </InputGroup.Text>
            <FormControl
              placeholder='Search..'
              aria-label="Search.."
              aria-describedby="projectSearch"
              value={projectList.searchString}
              onChange={handleSearch}
            />
          </InputGroup>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="project-list">

            {filterList !== undefined && (
              <div className="project-item-wrap">
                {filterList.map(project => {
                  return (
                    <>
                      <div key={`project-${project.id}`}
                        className={` project-list_item d-flex ${project.isChecked ? ' active' : ''}`}
                        onClick={() => handleProjectItemShow(project)}>
                        <div className="project-list_item--image">
                          <div className="project-img">
                            {
                              project.display_image === "" || null ? (
                                <Image src={NoImg} />
                              ) : (
                                <Image src={project.display_image} />
                              )
                            }
                            <span className='project-icon green'>
                              <Image src={project.type_list_icon} />
                            </span>

                          </div>

                        </div>
                        <div className="project-list_item--info">
                          <h3>{project.name}</h3>
                          <p>{project.address}</p>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {
        (activeProject !== undefined) && (
          <ProjectItem key={activeProject.slug}
            handleClose={handleClose}
            projectItemShow={projectItemShow}
            handleProjectItemShow={handleProjectItemShow}
            project={activeProject}
          />
        )
      }
      <MapToggle show={show} />
    </>
  )
}
const mapStateToProps = createStructuredSelector({
  projectList: projectListSelector,
  filterList: selectFilteredList,
  activeProject: selectActiveProject
})

const mapDispatchToProps = dispatch => ({
  filteredList: filteredList => dispatch(setFilteredList(filteredList)),
  updateActive: projectList => dispatch(updateActiveProject(projectList)),
  setCenter: aboutApp => dispatch(setCenter(aboutApp)),
  setZoom: aboutApp => dispatch(setZoom(aboutApp))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSidemenu)
