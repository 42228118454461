import React, { useState } from 'react'
import { Button, Carousel, Image, Modal } from 'react-bootstrap';

function ProjectImageComponent({ project }) {
  const [showImage, setShowImage] = useState(false);

  const handleImageClose = () => setShowImage(false);
  const image = project.detail.find(detail => detail.type === "image")

  return (
    <>
      {(image?.value?.length > 0) && (<>
        <div className='image-carousel bg-white bordered'>
          <h5>Photos</h5>
          <Carousel>
            {image.value.map(img => (
              <Carousel.Item>
                <Button onClick={() => setShowImage(true)} className='image-grid_item'>
                  <Image src={img.thumbnail} />
                </Button>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        {/* Image view Modal */}
        <Modal show={showImage} onHide={handleImageClose} centered className='photo-modal'>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          <Carousel>
            {image.value.map(img => (
              <Carousel.Item>
                <Image src={img.image} />
                {(img.credit_link) && (<a href={img.credit_link} className="credit-link" target="_blank">Source</a> )}
              </Carousel.Item>
            ))}
          </Carousel>
          </Modal.Body>
        </Modal>
      </>
      )}

    </>

  )
}

export default ProjectImageComponent