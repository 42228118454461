export const filteredData = (projectList, sortType, searchterm) => {
  let newSearchList = [...projectList];
  if (searchterm !== '') {
    newSearchList = newSearchList.filter((projects) =>
      Object.values(projects).join(" ").toLowerCase().includes(searchterm.toLowerCase())
    )
  }
  if (sortType === 'date')
    newSearchList = newSearchList.sort((a, b) => Date.parse(new Date(a.start_date)) - Date.parse(new Date(b.start_date)));
  else if (sortType === 'name')
    newSearchList = newSearchList.sort((a, b) => (a.name).localeCompare(b.name));
  else if (sortType === 'budget')
    newSearchList = newSearchList.sort((a, b) => Number(a.cost) - Number(b.cost))
  return newSearchList
}

export const handleFilter = (id, projectList) => {
  projectList.map((pr) => {
    return pr.isChecked = (pr.id === id)
  })
  return projectList
}

export const getActiveProject = (projectList) => {
  return projectList.find(project => project.isChecked)
}