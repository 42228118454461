import React from 'react'
import { Image} from 'react-bootstrap';
import HeaderSection from '../../components/header-section/header-section.component';
import FooterSection from '../../components/footer-section/footer-section.component';
import ContactUs from '../../components/contact-us.component/contact-us.component';
import ContactBanner from '../../assets/images/contact-bg.png';
import './contact-us.styles.scss';

const ContactPage = () => {
  // const [details, setDetails] = useState(INITIAL_STATE)
  // const [isSuccess, setIsSuccess] = useState(null)
  // const [successMsg, setSuccessMsg] = useState()
  // const [errMsg, setErrMsg] = useState(null)
  // const handleChange = async (e) => {
  //   const formDetail = { ...details, [e.target.name]: e.target.value }
  //   setDetails(formDetail);
  //   const validate = await contactSchema.validate(formDetail, { abortEarly: false }).then(() => {
  //   }).catch((err) => {
  //     const validationErrors = {}
  //     err.inner.forEach(err => {
  //       if (err.path) {
  //         validationErrors[err.path] = err.message;
  //       }
  //     })
  //     return validationErrors
  //   })
  //   setErrMsg(validate)
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   contactSchema.validate(details, { abortEarly: false }).then(() => {
  //     const body = `name=${details.name}&email=${details.email}&project_name=${details.project_name}&contact_person=${details.contact_person}&message=${details.message}`
  //     agent.infraData.addMessage(body).then((res) => {
  //       if (res.data) {
  //         setIsSuccess(true)
  //         setDetails(INITIAL_STATE)
  //         setSuccessMsg(res.data.message)
  //       } else {
  //         setIsSuccess(false)
  //         setSuccessMsg(res.errors[0].detail)
  //       }
  //     }).catch((err) => {
  //       setIsSuccess(false)
  //     })
  //   }).catch((err) => {
  //     const validationErrors = {}
  //     err.inner.forEach(err => {
  //       if (err.path) {
  //         validationErrors[err.path] = err.message;
  //       }
  //     })
  //     setErrMsg(validationErrors)
  //   })
  // }

  return (
    <>
      <HeaderSection />
      <div className="contact">
        <div className="contact-banner">
          <Image src={ContactBanner} className="img-fluid" />
          <div className="contact-overlay"></div>
          <div className="contact-banner_content">
            <h1>Contact Us</h1>
            <p>Drop us a line, or give us a heads up if you’re interested in visiting us.</p>
          </div>
        </div>
        <ContactUs />
        {/* <div className="contact-form bg-white">
          <Container>
            <Form onSubmit={handleSubmit}>
              <h1 className="contact-form_title">Get In Touch</h1>

              <div className="contact-form_feilds">
                {isSuccess && (
                  <Alert variant="success" onClose={() => setIsSuccess(false)} dismissible>{successMsg}</Alert>
                )}
                <Row>
                  <Col md={6}>
                    {console.log(errMsg)}
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Control type="text"
                        placeholder="Name and Surname"
                        name='name'
                        value={details.name}
                        required
                        onChange={handleChange}
                        isInvalid={errMsg?.name} />
                      <Form.Control.Feedback type="invalid">
                        {errMsg?.name}
                      </Form.Control.Feedback>

                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control type="text"
                        placeholder="Email"
                        name='email'
                        required
                        value={details.email}
                        onChange={handleChange}
                        isInvalid={errMsg?.email} />
                      <Form.Control.Feedback type="invalid">
                        {errMsg?.email}
                      </Form.Control.Feedback>

                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="projectname">
                      <Form.Control type="text"
                        placeholder="Project Name (if available)"
                        name='project_name'
                        value={details.project_name}
                        onChange={handleChange}
                        isInvalid={errMsg?.project_name} />
                      <Form.Control.Feedback type="invalid">
                        {errMsg?.project_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="person">
                      <Form.Control type="texts"
                        placeholder="Contact Person (if available)"
                        name='contact_person'
                        value={details.contact_person}
                        onChange={handleChange}
                        isInvalid={errMsg?.contact_person} />
                      <Form.Control.Feedback type="invalid">
                        {errMsg?.contact_person}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="person">
                      <Form.Control as="textarea"
                        name="message"
                        rows={3}
                        placeholder='Your Message'
                        value={details.message}
                        onChange={handleChange}
                        required
                        isInvalid={errMsg?.message} />
                      <Form.Control.Feedback type="invalid">
                        {errMsg?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button className='btn-lg' type="submit">
                  Submit Now
                </Button>
              </div>
            </Form>
          </Container>
        </div> */}
      </div>
      <FooterSection />
    </>
  )
}

export default ContactPage