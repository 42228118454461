import React, { useState } from "react";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HeaderSection from "../../components/header-section/header-section.component";
import MapSection from "../../components/map-section/map-section.component";
import SearchSection from "../../components/search-section/search-section.component";
import InternalServerError from '../../components/internal-server-error/internal-server-error.component'
import FooterSectionComponent from "../../components/footer-section/footer-section.component";
import { selectAboutApp } from '../../redux/about-app/about-app.selectors'
import { setProjectList } from '../../redux/project/project.action';
import { setCenter, setZoom } from '../../redux/about-app/about-app.actions';
import agent from '../../agent';



function createUrl(str, key, value) {
  return (str === "") ? `${key}=${value}` : `${str}&${key}=${value}`;
}

const HomePage = ({ aboutApp, setProjectList, setCenter, setZoom }) => {
  const [show, setShow] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [searchCountry, setSearchCountry] = useState({})

  const fetchData = async (fxn) => {
    try {
      const res = await fxn
      if (res.errors) {
        throw new Error(Number(res.errors[0].code))
      } else {
        return res
      }
    } catch (e) {
      errorCheck()
    }
  }
  const errorCheck = () => {
    setServerError(true)
  }

  const searchHandler = async (e) => {
    let searchStr = ""
    e.preventDefault();
    if (searchString !== "")
      searchStr = createUrl(searchStr, "name", searchString)
    if (searchCountry.value !== undefined)
      searchStr = createUrl(searchStr, "donor_country", searchCountry.value);
    console.log("header project list")
    const projectList = await fetchData(agent.infraData.getProject(searchStr))
    if (projectList.project) {
      setProjectList(projectList.project);
      setCenter(projectList.centroid);
      setZoom(aboutApp.zoom)
    }
  }

  const resetSearchFilter = async () => {
    setSearchString("")
    setSearchCountry({})
    const projectList = await fetchData(agent.infraData.getProject())
    if (projectList) {
      setProjectList(projectList.project);
      setCenter(projectList.centroid)
      setZoom(aboutApp.zoom)
    }
  }

  const handleCountry = (value) => {
    setSearchCountry(value)
  }


  return (<>
    {serverError ? <InternalServerError /> :
      <>
        <div className='header_home'>
          <HeaderSection className='header_home' resetSearchFilter={resetSearchFilter} />
        </div>
        <SearchSection errorCheck={errorCheck} fetchData={fetchData} searchHandler={searchHandler} resetSearchFilter={resetSearchFilter} searchString={searchString} searchCountry={searchCountry} setSearchString={setSearchString} handleCountry={handleCountry} />
        <MapSection />

        <div className={`home-footer ${show ? 'show' : ""}`}>
          <button type="button" className="home-footer-btn" onClick={() => setShow(!show)}><i className="fa-solid fa-caret-up"></i></button>
          <FooterSectionComponent />
        </div>
      </>
    }
  </>)
}

const mapStateToProps = createStructuredSelector({
  aboutApp: selectAboutApp
})

const mapDispatchToProps = dispatch => ({
  setProjectList: projectList => dispatch(setProjectList(projectList)),
  setCenter: aboutApp => dispatch(setCenter(aboutApp)),
  setZoom: aboutApp => dispatch(setZoom(aboutApp))

})
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)