import React from 'react'
import { Offcanvas, Image } from 'react-bootstrap';

import { Link } from 'react-router-dom';
// import TypeIconGreen from '../../../assets/images/icons/hydro-icon.svg';
import './project-item.styles.scss';
function ProjectItem({ projectItemShow, handleClose, project }) {
  return (
    <div>
      <Offcanvas className="profile-sidemenu" show={projectItemShow} onHide={handleClose} placement="end" scroll={true} backdrop={false}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5>Details</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="project-list_item">
            {(project.display_image !== "" && project.display_image !== null) && (<div className="project-list_item--image--right">
              <div className="project-img-right">
                <Image src={project.display_image} />
              </div>
            </div>)}
          </div>
          <div className="project-list_item">
            <div className="project-list_item--info ">
              <h3>{project.name}</h3>
              <p>{project.project_status}</p>
            </div>
          </div>
          <div className="button-group mb-3">
            {/* <Button>Map</Button> */}
            <Link to={`/project/detail/${project.id}`} target="_blank" className='btn btn-info'>View Details</Link>
            {/* <Button className='btn-info'>Share</Button> */}
          </div>
          <div>
            <div className="profile-info">
              <h5 className="profile-info_head">Basic Information</h5>
              <div className="profile-info_body">
                <div className="basic-info">
                  <h6 className="basic-info_head">Project Type:</h6>
                  <p>{project?.type ?? "N/A"}</p>
                </div>
                <div className="basic-info">
                  <h6 className="basic-info_head">Funding Type:</h6>
                  <p>{project?.funding_type ?? "N/A"}</p>
                </div>
                <div className="basic-info">
                  <h6 className="basic-info_head">Cost:</h6>
                  <p>{project?.cost_in_words ?? "N/A"}</p>
                </div>
                <div className="basic-info">
                  <h6 className="basic-info_head">Location:</h6>
                  <p>{project?.address ?? "N/A"}</p>
                </div>
                <div className="basic-info">
                  <h6 className="basic-info_head">Donor Country:</h6>
                  <p>{project?.donor_country ?? "N/A"}</p>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}


export default ProjectItem
