import { AboutAppActionTypes } from "./about-app.types";

export const setAboutApp = aboutApp => ({
  type: AboutAppActionTypes.SET_ABOUT_APP,
  payload: aboutApp
})

export const setCenter = aboutApp => ({
  type: AboutAppActionTypes.SET_CENTER,
  payload: aboutApp
})

export const setZoom = aboutApp => ({
  type: AboutAppActionTypes.SET_ZOOM,
  payload: aboutApp
})
