import superagentPromise from "superagent-promise";
import _superagent from "superagent";
// import { decode } from "geojson-polyline";
// import uuid from "react-uuid";
// import { camelize } from "./utils/util";
import { isEmpty } from "lodash";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = res => res.body;
const AuthParams = `client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&grant_type=${process.env.REACT_APP_GRANT_TYPE}`;

const getAuthToken = () => {
  if (!isEmpty(JSON.parse(window.localStorage.getItem("authToken"))))
    return JSON.parse(window.localStorage.getItem("authToken"));
  else
    infraAuth();
};

const checkError = (res) => {
  if (res.response.status === 401) {
    infraAuth();
  }
  return res.response.body
}

const infraAuth = async () => {
  const authToken = await superagent.post(`${process.env.REACT_APP_BASE_API_URL}client-get-token`, AuthParams)
    .set("Content-Type", "application/x-www-form-urlencoded")
    .set("Accept", "application/json")
    .then((res) => {
      return res.body;
    })
    .catch((err) => {
      throw err;
    })
  window.localStorage.setItem("authToken", JSON.stringify(authToken));
  window.location.reload()
}

const infraData = {
  getAboutApp: () => dataRequests.get("about-apps")
    .then(response => {
      return response.data
    }).catch(err => checkError(err)),
  getTestimonials: () => dataRequests.get("testimonials")
    .then(response => response.data
    ).catch(err => checkError(err)),
  getBaseMap: () => dataRequests.get("base-maps")
    .then(response => {
      return response.data['base-map']
    }).catch(err => checkError(err)),
  getPages: (slug) => dataRequests.get(`pages?slug=${slug}`)
    .then(response => response)
    .catch(err => {
      checkError(err)
      return { status: err.status }
    }),

  getProject: (query = "") => dataRequests.get(`projects?${query === "" ? "" : query}`)
    .then(response => {
      return response.data
    }).catch(err => {
      checkError(err)
      return { status: err.status }
    }),
  getProjectList: (paginate = false, page = 1, query = "") => dataRequests.get(`projects?paginate=${paginate}&page=${page}&${query}`)
    .then(response => response.data).catch(err => checkError(err)),
  getProjectFilter: () => dataRequests.get(`get-project-filters`)
    .then(response => {
      return response.data.filter
    }).catch(err => checkError(err)),
  getProjectInfo: (pId) => dataRequests.get(`projects/${pId}`)
    .then(response => response
    ).catch(err => checkError(err)),
  addMessage: (body) => dataRequests.post("contact-us", body)
    .then(response => response).catch(err => checkError(err)),
}

const dataRequests = {
  get: url =>
    superagent.get(`${process.env.REACT_APP_BASE_API_URL}${url}`).set("Authorization", `${getAuthToken().token_type} ${getAuthToken().access_token}`).then(responseBody).catch(err => { throw (err) }),
  post: (url, body) =>
    superagent.post(`${process.env.REACT_APP_BASE_API_URL}${url}`, body).set("Authorization", `${getAuthToken().token_type} ${getAuthToken().access_token}`).then(responseBody).catch(err => { throw (err) }),
  put: (url, body) =>
    superagent.put(`${process.env.REACT_APP_BASE_API_URL}${url}`, body).set("Authorization", `${getAuthToken().token_type} ${getAuthToken().access_token}`).then(responseBody).catch(err => { throw (err) }),
  delete: (url) =>
    superagent.del(`${process.env.REACT_APP_BASE_API_URL}${url}`).set("Authorization", `${getAuthToken().token_type} ${getAuthToken().access_token}`).then(responseBody).catch(err => { throw (err) }),
}

export default { infraData, getAuthToken }
