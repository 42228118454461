import { createPathComponent } from '@react-leaflet/core';
import "leaflet-boundary-canvas";
import L from 'leaflet';

const CanvasBoundary = createPathComponent(
  ({ children: _c, ...props }, ctx) => {
     const osm = L.TileLayer.boundaryCanvas(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        boundary: props.boundary,
        attribution: props.attribution

      }
    );
    return {
      instance: osm,
      context: { ...ctx, layerContainer: osm },
    };
  }
);

export default CanvasBoundary;