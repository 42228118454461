import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import './project-detail.styles.scss';
import FooterSection from '../footer-section/footer-section.component';
import HeaderSection from '../header-section/header-section.component';
import { Col, Container, Image, Row, Carousel } from 'react-bootstrap';
import ProjectDetailContact from './project-contact/project-contact.component';
import agent from '../../agent'
import ProjectInformationComponent from './project-info/project-informations.component';
import ProjectImageComponent from './project-image-carousel/project-image.component';
import PageNotFound from '../page-not-found/page-not-found.component';
import InternalServerError from '../internal-server-error/internal-server-error.component';

function ProjectDetail() {
  const { id } = useParams()
  const [project, setProject] = useState({})
  const [notFound, setNotfound] = useState(false)
  const [serverError, setServerError] = useState(false)
  useEffect(() => {
    const fetchProjectDetail = () => {
      agent.infraData.getProjectInfo(id).then(res => {
        if (res.errors) {
          throw new Error(Number(res.errors[0].code))
        } else {
          setProject(res.data.project)
        }
      }).catch(err => {
        if (Number(err.message) === 404) {
          setNotfound(true)
        }
        if (Number(err.message) === 500) {
          setServerError(true)
        }
      })
    }
    fetchProjectDetail()
  }, [id, notFound, serverError])
  return (
    <>
      { notFound ? <PageNotFound /> : serverError ? <InternalServerError /> :
          <>
            <HeaderSection />
            <div className='project'>
              {
                project.banner !== "" && project.banner !== null &&
                (
                  <div className="project-banner">
                    <div className="overlay"> </div>
                    <Carousel>
                      <Carousel.Item>
                        <Image src={project.banner} className='carousel-image' />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                )
              }
              {Object.keys(project).length > 0 && (
                <>
                  <Container>
                    <div className="project-detail d-flex justify-content-between align-items-center">
                      <div className="project-detail-title d-flex align-items-center">
                        <Image src={project.display_image} />
                        <div>
                          <h1>{project.name}</h1>
                          <p>{project.address}</p>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col xl={9}>
                        <ProjectInformationComponent project={project} />
                      </Col>
                      <Col xl={3}>
                        <ProjectImageComponent project={project} />
                        <ProjectDetailContact project={project} />
                      </Col>
                    </Row>
                  </Container>
                </>
              )
              }
            </div>
            <FooterSection />
          </>
      }
    </>
  )
}

export default ProjectDetail