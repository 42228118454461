import { AboutAppActionTypes } from "./about-app.types";
import config from '../../config/env_config.json';
const INITIAL_STATE = {
  aboutApp: null,
  majorProjects: [],
  center: [config.DEFAULT_LAT, config.DEFAULT_LNG],
  zoom: config.DEFAULT_ZOOM,
  attr: config.ATTRIBUTION,
}

const aboutAppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AboutAppActionTypes.SET_ABOUT_APP:
      return {
        ...state,
        aboutApp: action.payload.about_app,
        majorProjects: action.payload.major_projects,
        // center: [Number(action.payload.about_app.latitude), Number(action.payload.about_app.longitude)],
        zoom: action.payload.about_app.zoom,
        baseZoom: action.payload.about_app.zoom,
        attr: action.payload.about_app.attribution
      }
    case AboutAppActionTypes.SET_CENTER:
      return {
        ...state,
        center: action.payload
      }
    case AboutAppActionTypes.SET_ZOOM:
      return {
        ...state,
        zoom: action.payload
      }
    default:
      return state
  }
}

export default aboutAppReducer;