import React from 'react'
import { Modal, Image } from 'react-bootstrap';
import './image-modal.styles.scss'

function ImageModal({ doc, fileLink, showModal, handleClose }) {
  return (
    <>
      {
        doc.file_link === fileLink && (
          <>
            <Modal centered backdrop='static' show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{doc.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Image src={doc.file_link} className="modal-image" height='auto' />
              </Modal.Body>
            </Modal>
          </>
        )
      }
    </>
  )
}

export default ImageModal

