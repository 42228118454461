import { ProjectActionTypes } from './project.types';

export const setProjectList = project => ({
  type: ProjectActionTypes.SET_PROJECT_LIST,
  payload: project
})

export const setPaginatedProject = project => ({
  type: ProjectActionTypes.SET_PAGINATED_PROJECT_LIST,
  payload: project
})

export const setFilteredList = project => ({
  type: ProjectActionTypes.SET_FILTERED_LIST,
  payload: project
})

export const updateActiveProject = project => ({
  type: ProjectActionTypes.UPDATE_PROJECT_ACTIVE,
  payload: project
})

export const setProjectFilterList = project => ({
  type: ProjectActionTypes.SET_PROJECT_FILTER_LIST,
  payload: project
})