import React from "react";
import './header-section.styles.scss'
import { connect } from 'react-redux';
import { setFilteredList, setProjectList } from '../../redux/project/project.action';
import { createStructuredSelector } from "reselect";
import { setAboutApp, setCenter, setZoom } from '../../redux/about-app/about-app.actions';
import { setProjectFilterList } from '../../redux/project/project.action';
import { selectCenter, selectAboutAppSelector } from '../../redux/about-app/about-app.selectors'
import { Link } from 'react-router-dom'
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Logo from '../../assets/images/infra_logo.png';

const HeaderSection = ({ filteredList, setProjectList, center, setCenter, setZoom, aboutApp, resetSearchFilter }) => {
  const handleReset = async () => {
    filteredList({ sortType: 'Sort By', searchTerm: '' })
    // setCenter(center)
    // setZoom(aboutApp.baseZoom)
    resetSearchFilter()
  }

  return (
    <header className="__header">
      <Navbar expand="lg"> {/* fixed="top" */}
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={handleReset}><Image src={Logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <NavLink to={"/"} className="nav-link">Map</NavLink>
              <NavLink to={"/projects"} className="nav-link">Projects</NavLink>
              <NavLink to={"/about"} className="nav-link">About</NavLink>
              <NavLink to={"/contact"} className="nav-link">Contact</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

const mapStateToProps = createStructuredSelector({
  center: selectCenter,
  aboutApp: selectAboutAppSelector,

})

const mapDispatchToProps = dispatch => ({
  filteredList: filteredList => dispatch(setFilteredList(filteredList)),
  setProjectFilterList: (projectList) => dispatch(setProjectFilterList(projectList)),
  setProjectList: projectList => dispatch(setProjectList(projectList)),
  setAboutApp: (aboutApp) => dispatch(setAboutApp(aboutApp)),
  setCenter: aboutApp => dispatch(setCenter(aboutApp)),
  setZoom: aboutApp => dispatch(setZoom(aboutApp)),

})



export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection)
