import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { selectActiveBasemap } from "../../redux/base-map/base-map.selectors";
import { selectAttribuation } from "../../redux/about-app/about-app.selectors";
import Boundary from '../../assets/data/nepal-boundary.json';
import CanvasBoundary from "./canvas-boundary";
const BaseMap = ({ activeMap, attr }) => {
  return (
    <>
      {console.log(activeMap)}
      {(activeMap !== undefined) && (
        (activeMap.provider === "OpenStreetMap") && (
          <TileLayer
            attribution={attr}
            url={activeMap.url}
          />
        )
      )}
      {(activeMap !== undefined) && (
        (activeMap.provider === "BOUNDARY") && (<CanvasBoundary boundary={Boundary} attribution={attr} />)
      )}
      {(activeMap !== undefined) && (
        (activeMap.provider === "Google") && (
          (activeMap.type === "") ?
            (<TileLayer attribution={attr} url={activeMap.url} />) :
            (<ReactLeafletGoogleLayer apiKey={activeMap.api_key} type={activeMap.type} />)
        )
      )}
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  activeMap: selectActiveBasemap,
  attr: selectAttribuation
})

export default connect(mapStateToProps)(BaseMap);