import React, { useState } from 'react';
import Helper from '../../../utils/helper';
import ImageModal from './image-modal.component';

function ProjectInformationComponent({ project }) {
  const [showModal, setShowModal] = useState(false)
  const [fileLink, setFileLink] = useState()

  const docs = project.detail.find(detail => detail.type === "document")

  const handleModalShow = () => {
    setShowModal(false)
  }
  const handleImageModal = (link) => {
    setShowModal(true)
    setFileLink(link)
  }
  return (
    <div className='project-info bg-white bordered'>
      <div className="project-info_block">
        <h3 className="project-info--title">Project Information</h3>
        <div className="project-info--detail">
          <h5>Project Name</h5>
          <p>{project?.name ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Location</h5>
          <p>{project?.address ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Type of Project</h5>
          <p>{project?.type ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Cost</h5>
          <p>{project?.cost_in_words ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Major Donor Country</h5>
          <p>{project?.donor_country ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Other Donor Country</h5>
          <p>{project?.other_donor_country ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Other Donor Institute</h5>
          <p>{project?.other_institute_donor ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Amount Of Aid</h5>
          <p>{project?.aid_amount ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Funding Type</h5>
          <p>{project.funding_type ? Helper.titleFormatter(project.funding_type) : "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Involved Companies</h5>
          {project.companies.length === 0 ? <p>N/A</p> : <p>{Object.values(project.companies).map(company => (<span className='extra' key={company.name}>{company?.name ?? "N/A"}<span className='divider'>/</span></span>))}</p>}
        </div>
        <div className="project-info--detail ">
          <h5>Known Financiers</h5>
          {project.financiers.length === 0 ? <p>N/A</p> : <p>{Object.values(project.financiers).map(finance => (<span className="extra" key={finance.name}>{finance?.name ?? "N/A"}<span className='divider'>/</span></span>))}</p>}

        </div>
        <div className="project-info--detail">
          <h5>Year of Agreement</h5>
          <p>{project?.year_of_aggrement ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Start Year</h5>
          <p>{project?.start_date ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>End Year</h5>
          <p>{project?.end_date ?? "N/A"}</p>
        </div>
        <div className="project-info--detail">
          <h5>Project Status</h5>
          <p> <span className={`badge ${project.project_status}`}>{project.project_status ? Helper.titleFormatter(project.project_status) : "N/A"}</span> </p>
        </div>
      </div>
      {
        project.detail.map((detail, index) => {
          return (
            < div key={index}>
              {(detail.type === "text" && detail.value !== null) && (<>
                <div className="project-info_block">
                  <h3 className="project-info--title">{detail.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: detail.value }}></p>
                </div>
              </>)}
            </div>)
        })
      }
      {
        (docs?.value?.length > 0) && (
          <div className="project-info_block">
            <h3 className="project-info--title">{docs.Header}</h3>
            <div className="project-info--table">
              <table>
                <tbody>
                  {docs.value.map((doc, index) => (
                    <tr key={index}>
                      <td>
                        {doc.file_type === 'image' ?
                          <>
                            <div style={{ color: '#477CEA', cursor: 'pointer' }} onClick={() => handleImageModal(doc.file_link)}>{doc.title}</div>
                            <ImageModal doc={doc} fileLink={fileLink} showModal={showModal} handleClose={handleModalShow} />
                          </>
                          :
                          <a href={doc.file_link} target="_blank">{doc.title}</a>
                        }
                      </td>
                      <td>
                        {doc?.document_owner}
                      </td>
                      <td>
                        {doc?.url && <a href={doc.url} target="_blank">Source Link</a>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div >
  )
}

export default ProjectInformationComponent