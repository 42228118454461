import React from 'react';
import { Routes, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { setProjectFilterList } from './redux/project/project.action';
import { setBaseMap } from './redux/base-map/base-map.actions';
import { setAboutApp } from './redux/about-app/about-app.actions';
import HomePage from './pages/Home/home-page.component';
import ContactPage from './pages/Contact/contact-page.component';
import ProjectsPage from './pages/Project/project-page.component';
import ProjectDetail from './components/project-detail-section/project-detail.component';
import AboutPage from './pages/About/about-page.component';
import Footerpage from './pages/FooterPage/footer-page-page.component';
import PageNotFound from './pages/page-not-found/page-not-found.component.jsx';
import agent from './agent';
import './styles/app.scss';

class App extends React.Component {

  async componentDidMount() {
    const {
      setBaseMap,
      setAboutApp,
      setProjectFilterList
    } = this.props
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
    setAboutApp(await agent.infraData.getAboutApp());
    setBaseMap(await agent.infraData.getBaseMap());
    setProjectFilterList(await agent.infraData.getProjectFilter())


  }
  render() {
    return (
      <>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/projects" element={<ProjectsPage />} exact />
          <Route path="/about" element={<AboutPage />} exact />
          <Route path="/contact" element={<ContactPage />} exact />
          <Route path="/project/detail/:id" element={<ProjectDetail />} exact />
          <Route path='/pages/:slug' element={<Footerpage />} exact />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setProjectFilterList: (projectList) => dispatch(setProjectFilterList(projectList)),
  setBaseMap: (baseMap) => dispatch(setBaseMap(baseMap)),
  setAboutApp: (aboutApp) => dispatch(setAboutApp(aboutApp))
})
export default connect(null, mapDispatchToProps)(App);


