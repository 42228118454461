import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import agent from '../../../agent';
function AboutUsEditorsComponent({ fetchData }) {
  const [testimonial, setTestimonial] = useState()
  useEffect(() => {
    const fetchTestimonial = async () => {
      let res = await fetchData(agent.infraData.getTestimonials())
      if (res !== undefined) {
        setTestimonial(res.testimonial)
      }
    }
    fetchTestimonial()
  }, [])
  return (
    <>
      {(testimonial !== undefined && testimonial?.length > 0) && (
        <div className='about-editors'>
          <h3 className="title mb-0 text-center">THE INFRASTRUCTURE'S EDITORS</h3>
          <div className="about-editors_card">
            <Row>
              {testimonial.map((data) =>
                <Col lg={6} xl={3} key={data.name}>
                  <div className="card text-center mb-3">
                    <div className="card-img">
                      <Image src={data.image} />
                    </div>
                    <div className="card-body">
                      <h5 className="name">{data.name}</h5>
                      <p>{data.description}</p>
                      {/* <Link to={'/about'}>Read More <i className="fa-solid fa-arrow-right-long"></i></Link> */}
                    </div>
                  </div>
                </Col>
              )
              }
            </Row>
          </div>
        </div >
      )}

    </>
  )
}

export default AboutUsEditorsComponent