import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectBaseMapList, selectActiveBasemap } from '../../redux/base-map/base-map.selectors';
import { updateActiveMap } from "../../redux/base-map/base-map.actions";
import { Button, Image, Nav } from 'react-bootstrap';
import './map-toggle.styles.scss';
function MapToggle({ show, baseMapList, activeMap, updateActiveMap }) {
  const [showMaps, setShowMaps] = useState(false)

  return (
    <div className={show ? 'map-toggle d-flex toggled' : 'map-toggle d-flex'}>
      {(activeMap !== undefined) && (
        <>
              <Button className='show-btn' onClick={() => setShowMaps(!showMaps)}>
        <Image src={activeMap.image} />
        <div className='overlay'>{activeMap.name}</div>
      </Button>
      {
        (showMaps) && (
          <Nav variant="pills" defaultActiveKey={activeMap.slug}>
            {baseMapList.map(basemap => {
              return (
                <Nav.Item key={basemap.slug}>
                <Nav.Link eventKey={basemap.slug} onClick={() => updateActiveMap(basemap)}>
                  <Image src={basemap.image} />
                  <p>{basemap.name}</p>
                </Nav.Link>
              </Nav.Item>)
            })}
          </Nav>
        )}
        </>
      )}


    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  baseMapList: selectBaseMapList,
  activeMap: selectActiveBasemap
})

const mapDispatchToProps = (dispatch) => ({
  updateActiveMap: (baseMap) => dispatch(updateActiveMap(baseMap)),
});

export default connect(mapStateToProps,mapDispatchToProps)(MapToggle)