import React, { useState } from 'react';
import HeaderSection from '../../components/header-section/header-section.component';
import FooterSection from '../../components/footer-section/footer-section.component';
import { Breadcrumb, Container, Image } from 'react-bootstrap';
import AboutBanner from '../../assets/images/about-banner.png';
import './about-us.styles.scss';
import AboutUsIntroComponent from '../../components/about-us/introduction/AboutUsIntro.component';
import AboutUsEditorsComponent from '../../components/about-us/editors/AboutUsEditors.component';
import InternalServerError from '../../components/internal-server-error/internal-server-error.component';


const AboutPage = () => {

  const [serverError, setServerError] = useState(false);

  const checkError = () => {
    setServerError(true)
  }

  const fetchData = async (fxn) => {
    try {
      const res = await fxn
      if (res.errors) {
        throw new Error(Number(res.errors[0].code))
      } else {
        return res
      }
    } catch (e) {
      checkError()
    }
  }

  return (<>
    {serverError ? <InternalServerError /> :
      <>
        <HeaderSection />
        <div className='about-wrap'>
          <div className="aboutUs-banner">
            <Image src={AboutBanner} />
            <div className="aboutUs-banner_content">
              <h2>ABOUT US</h2>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>About Us</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="aboutUs-content">
            <Container>
              <AboutUsIntroComponent />
              <AboutUsEditorsComponent fetchData={fetchData} />
            </Container>

          </div>
        </div>
        <FooterSection />
      </>

    }

  </>
  )
}

export default AboutPage