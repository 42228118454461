import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAboutApp } from '../../../redux/about-app/about-app.selectors';
import { Col, Image, Row } from 'react-bootstrap';
function AboutUsIntroComponent({ aboutApp }) {
  return (
    <>
      <div className="intro">
          {aboutApp !== null && (
            <>
              <div className='h-100 d-flex align-items-center'>
                <div className="intro-block">
                  <div className="intro-img mb-3">
                    <Image src={aboutApp.image} className='img-fluid w-100' />
                  </div>
                  <h3 className='title'>About Us</h3>
                  <p className='description' dangerouslySetInnerHTML={{ __html: aboutApp.description }}></p>
                  {/* <Button className='btn-lg'>Read More <i className="fa-solid fa-arrow-right-long"></i></Button> */}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  aboutApp: selectAboutApp
})

export default connect(mapStateToProps)(AboutUsIntroComponent);