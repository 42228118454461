import React, { useState } from "react"
import Autosuggest from 'react-autosuggest';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setPaginatedProject } from "../../redux/project/project.action";
import { selectProjectFilterList } from "../../redux/project/project.selector";
// import Multiselect from 'multiselect-react-dropdown';
import Helper from "../../utils/helper";
import agent from "../../agent";

const INITIAL_STATE = {
  "country": {},
  "company": {},
  "financier": {},
  "project_type": {},
  "project_status": {},
  "project_cost": {}
}

function ProjectDatabaseFilter({ projectFilterList, setPaginatedProject }) {
  const [search, setSearch] = useState(INITIAL_STATE)
  const [suggestions, setSuggestions] = useState([])
  const [searchStr, setSearchStr] = useState("")
  const [dropdownKey, setDropdownKey] = useState("")
  const [dropdownValue, setDropdownValue] = useState("")

  const handleDropdown = (key, value) => {
    setDropdownKey(key)
    setDropdownValue(value)
    let copiedShopCart = { ...search };
    copiedShopCart[key] = value
    setSearch(copiedShopCart)
  }

  const projectSearch = async () => {
    let urlString = "";
    if (searchStr !== "")
      urlString = `name=${searchStr}`
    Object.keys(search).map(item => {
      if (search[item].value !== undefined) {
        urlString = (urlString === "") ? `${item}=${search[item].value}` : `${urlString}&${item}=${search[item].value}`
      }
    })
    setPaginatedProject(await agent.infraData.getProjectList(true, 1, urlString))
  }

  const projectReset = async () => {
    setSearch(INITIAL_STATE)
    setSearchStr("")
    setPaginatedProject(await agent.infraData.getProjectList(true, 1, ""))
  }

  return (
    <div className="filter-section">
      <Container>
        <Row>
          <Col>
            <div className="position-relative">
              <Autosuggest
                inputProps={{
                  className: 'form-control',
                  placeholder: "Search By Project Name",
                  value: searchStr,
                  onChange: (_event, { newValue }) => {
                    setSearchStr(newValue)
                  }
                }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={async ({ value }) => {
                  if (!value) {
                    setSuggestions([])

                  } else {
                    let matches = []
                    matches = projectFilterList?.project_title.filter((title) => {
                      if (title.toLowerCase().includes(value.toLowerCase())) {
                        matches.push(title)
                        setSuggestions(matches.slice(0.5))
                      }
                    })
                  }
                }
                }
                onSuggestionsClearRequested={() => {
                  setSuggestions([])
                }}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={suggestion => <>{suggestion}</>}
              />
            </div>
          </Col>
          {(projectFilterList !== null) && (

            <>
              {Object.keys(projectFilterList).map((key) =>
              (
                <Col key={key}>
                  <Dropdown className='select_block'>
                    {
                      key !== 'project_title' && (
                        <Dropdown.Toggle id={key}>
                          <span>{search?.[key]?.label ?? Helper.titleFormatter(key)}</span>
                          <i className="fa-solid fa-angle-down"></i>
                        </Dropdown.Toggle>
                      )
                    }
                    <Dropdown.Menu>
                      <Dropdown.Item key={`${key}-${key}`} onClick={() => handleDropdown(key, {})}>-- {Helper.titleFormatter(key)} --</Dropdown.Item>
                      {projectFilterList[key].map(item => (

                        <Dropdown.Item key={`${key}-${item.value}`} onClick={() => handleDropdown(key, item)}>{item.label}</Dropdown.Item>)

                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )
              )}
            </>)
          }
          {/* <Col>
            <Multiselect
              options={options} // Options to display in the dropdown
              // selectedValues={} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </Col> */}
          <Col>
            <Button onClick={projectSearch}>Search</Button>
          </Col>
          <Col>
            <Button className='reset-btn' type="reset" onClick={projectReset}>
              <span><i className="fa-solid fa-arrow-rotate-right"></i></span> Reset Search
            </Button>

          </Col>
        </Row>
      </Container>
    </div >
  )
}

const mapStateToProps = createStructuredSelector({
  projectFilterList: selectProjectFilterList,
})

const mapDispatchToProps = dispatch => ({
  setPaginatedProject: (projectList) => dispatch(setPaginatedProject(projectList))
})
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDatabaseFilter)