import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { createStructuredSelector } from 'reselect';
import { selectProjectFilterList, selectFilteredList } from '../../redux/project/project.selector';
import { selectAboutApp } from '../../redux/about-app/about-app.selectors';
import { setProjectList } from '../../redux/project/project.action';
import { setCenter, setZoom } from '../../redux/about-app/about-app.actions';
import { Button, Container, Dropdown, Form, InputGroup } from 'react-bootstrap';
import agent from '../../agent';
import './search-section.styles.scss'


const SearchSection = ({ projectFilterList, setProjectList, fetchData, searchHandler, resetSearchFilter,setCenter , searchString, searchCountry, setSearchString, handleCountry }) => {

  const [suggestions, setSuggestions] = useState([])
  useEffect(() => {
    const fetchProject = async () => {
      let projectList = await fetchData(agent.infraData.getProject())
      if (projectList !== undefined) {
        setProjectList(projectList.project)
        setCenter(projectList.centroid)
      }
    }
    fetchProject();
  }, []);

  return (
    <>
      <div className="nav-filter" >
        <Container>
          <div className="nav-filter_block d-flex align-items-center justify-content-end">
            {/* <h3 className='nav-filter_title me-3'>Mapping South Asia</h3> */}
            <Form>
              <div className='form-block d-flex align-items-center justify-content-between flex-wrap'>
                <Form.Group className="form-block_item">
                  <div className='input-group'>
                    <Autosuggest
                      inputProps={{
                        className: 'form-control',
                        placeholder: "Search By Project Name",
                        value: searchString,
                        onChange: (_event, { newValue }) => {
                          setSearchString(newValue)
                        }
                      }}
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={async ({ value }) => {
                        if (!value) {
                          setSuggestions([])
                        } else {
                          let matches = []
                          matches = projectFilterList?.project_title.filter((title) => {
                            if (title.toLowerCase().includes(value.toLowerCase())) {
                              matches.push(title)
                              setSuggestions(matches.slice(0, 5))
                            }
                          })
                        }
                      }
                      }
                      onSuggestionsClearRequested={() => {
                        setSuggestions([])
                      }}
                      getSuggestionValue={suggestion => suggestion}
                      renderSuggestion={suggestion => <div className='sudarshan'>{suggestion}</div>}
                    />
                    <InputGroup.Text id="search-feild"></InputGroup.Text>
                  </div>

                </Form.Group>
                {(projectFilterList !== null || undefined) && (
                  <>
                    <Form.Group className="form-block_item" controlId="formBasicSelect2">
                      <Dropdown className='select_block'>
                        <Dropdown.Toggle id="dropdown-basic" className="selected_text">
                       <span>{searchCountry.label ? searchCountry.label : "Donor Countries"}</span>

                          <i className="fa-solid fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item key="country-nan" onClick={() => handleCountry({})}>--Select Country--</Dropdown.Item>
                          {projectFilterList.donor_country.map(country =>
                            (<Dropdown.Item key={`country-${country.value}`} onClick={() => handleCountry(country)}>{country.label}</Dropdown.Item>))
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </>
                )}
                <div className="form-block_item mx-2">
                  <Button className='btn btn-lg' type="submit" onClick={searchHandler}>
                    Search
                  </Button>
                </div>
                <div className="form-block_item">
                  <Button className='reset-btn' type="reset" onClick={resetSearchFilter}>
                    <span><i className="fa-solid fa-arrow-rotate-right"></i></span> Reset
                  </Button>
                </div>
                {/* <div className="form-block_item">
                <Form.Group controlId="saveYourSearch">
                  <Form.Check type="checkbox" label="Save your search" />
                </Form.Group>
              </div> */}
              </div>
            </Form>
          </div>

        </Container>
      </div >
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  projectFilterList: selectProjectFilterList,
  filterList: selectFilteredList,
  aboutApp: selectAboutApp
})

const mapDispatchToProps = dispatch => ({
  setProjectList: projectList => dispatch(setProjectList(projectList)),
  setCenter: aboutApp => dispatch(setCenter(aboutApp)),
  setZoom: aboutApp => dispatch(setZoom(aboutApp))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection)