import React from 'react'
import { Link } from 'react-router-dom';
import './internal-server-error.style.scss'

function InternalServerError() {
    return (
        <>
            <div className="page">
                <div className="page-not-found">
                    <h1>500</h1>
                    <p>Internal Server Error</p>

                    <Link to={"/"} className="btn btn-primary">Back to homepage <i className="fa-solid fa-arrow-right-long"></i></Link>
                </div>

            </div>
        </>
    )
}

export default InternalServerError